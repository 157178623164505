export const insignificantWords = new Set([
  // Prepositions
  "in", "on", "over", "with", "at", "by", "for", "from", "into", "of", "off", "out",
  "through", "to", "up", "upon", "under", "about", "among", "as", "before", "behind",
  "below", "beneath", "beside", "between", "beyond", "during", "inside", "near",
  "outside", "throughout", "toward", "within", "without",

  // Conjunctions
  "and", "or", "but", "nor", "yet", "so", "for", "although", "because", "if",
  "since", "unless", "while", "after", "before", "even if", "even though",
  "in order that", "once", "so that", "than", "though", "until", "when",
  "where", "either", "neither", "that", "not only", "but also", "both",
  "whether", "no sooner", "the more", "rather", "this", "these", "those",
  "here", "there", "it", "such", "which", "what", "them",

  // Articles
  "the", "a", "an"
]);
